import React, {
  useEffect,
  useState
} from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { Link, navigate } from "gatsby"

import SEO from "@seo"

import Card from "@atoms/card"

import Form from "@organisms/form"

import { userSave } from "@store/account/actions"
import { getUser } from "@store/account/selectors"
import fetcher from "@store/fetcher"
import { getFetch } from "@store/fetcher/selectors"

import { subapps } from "@constants/enums"

import { subapp } from "@utils/functions"

import { LayoutCenteredStyled } from "@styles/layout"

const isDev = process.env.NODE_ENV === "development"

const Home = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const _fetcher = (path, options, callbacks) =>
    dispatch(fetcher(path, options, callbacks))
  const _userSave = data => dispatch(userSave(data))

  const user = useSelector(getUser)
  const request = useSelector(state => getFetch(state, "POST.auth.login"))
  const [error, setError] = useState(null)

  const isAuthenticated = !!user.id && !!user.activeLayout

  useEffect(() => {
    if (isAuthenticated) {
      switch (subapp) {
        case subapps.TOUR:
          navigate(`/events`)
          break
        case subapps.HELP:
        default:
          navigate(`/requests`)
          break
      }
    } else if (!!user.id) {
      navigate("/logout")
    }
  }, [isAuthenticated])

  const submit = values => {
    const params = {
      ...values,
    }
    const options =  { body: params }

    const onFetchLogin = {
      success: ({ token, refreshToken, item }) => {
        const { id } = item
        if (!token || !refreshToken || !id) return
        _userSave({ token, refreshToken, id, activeLayout: true })
      },
      error: ({ message }) => {
        setError(message)
      },
    }

    _fetcher("POST.auth.login", options, onFetchLogin)
  }

  const onChange = values => {
    setError(null)
  }

  const loading = request.loading

  const mock = {
    email: "kristian.barrese+operator@enhancers.it",
    password: "kristian123",
  }

  const initialValues = isDev
    ? mock
    : {
        email: "",
        password: "",
      }

  const fields = [
    {
      name: "email",
      type: "email",
      required: true,
    },
    {
      name: "password",
      type: "password",
      required: true,
    },
  ]

  return (
    <LayoutCenteredStyled>
      <>
        <SEO title={t("titles.login")} />

        <div>
          <Card title={t("login.title")}>
            <Form
              initialValues={initialValues}
              fields={fields}
              loading={loading}
              disabled={() => loading}
              submitText={t("login.submit")}
              error={error}
              onChange={onChange}
              onSubmit={submit}
            />
            <Link to="forgot-password" className="link">
              {t("login.forgot_link")}
            </Link>
          </Card>
        </div>
      </>
    </LayoutCenteredStyled>
  )
}

export default Home
