import styled from "styled-components"

import Layout from "@layout"

export const LayoutCenteredStyled = styled(Layout)`
  display: flex;
  align-items: center;
  justify-content: center;

  .centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .textCenter {
    text-align: center;
  }

  .rectangle {
    border: 1px ${({ theme }) => theme.palette.common.gainsboro} solid;
    border-radius: 8px;
    text-align: center;
    padding: 20px;
    margin: 10px auto;

    h3 {
      margin: 8px auto;
    }

    b {
      color: ${({ theme }) => theme.palette.common.grey};
    }

    p {
      margin-bottom: 6px;
    }

    &.red {
      background-color: #ffebeb;
    }
  }

  .link {
    display: block;
    text-align: center;
    margin: 0 auto;
    margin-top: 20px;
    font-size: 14px;
    text-decoration: none;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`
